import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div style={{ textAlign: "center" }}>
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      <a href="/" title="Home" style={{ color: "var(--mainMediumAquamarine)" }}>
        Go Back Home
      </a>
    </div>
  </Layout>
)

export default NotFoundPage
